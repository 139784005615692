import React from "react";

import "./navbar.css";

const Navbar = () => {
  return (
    <header data-thq="thq-navbar" class="navbar-navbar text-white">
      <div class="navbar-left">
        <a href="index.html" class="navbar-navlink">
          <img
            alt="image"
            src="public/Branding/bes%20logo%20(1)-1-200h.png"
            class="navbar-logo"
          />
        </a>
        <nav class="navbar-links">
          <a href="projects.html" class="navbar-link">
            <span>Projects</span>
            <br />
          </a>

          <a href="services.html" class="navbar-link2">
            <span>Services</span>
          </a>
          <a href="clients.html" class="navbar-link3">
            <span>Clients</span>
          </a>
        </nav>
      </div>
      <div data-thq="thq-navbar-btn-group" class="navbar-right">
        <button class="navbar-phone button">
          <img alt="image" src="public/Icons/phone.svg" class="navbar-image" />
          <a href="tel:+968-71160030" class="navbar-link4">
            <span>+968-71160030</span>
          </a>
        </button>
        <a href="#book" class="navbar-book button button-main">
          <svg viewBox="0 0 1024 1024" class="navbar-icon">
            <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM256 256h512c9.138 0 18.004 1.962 26.144 5.662l-282.144 329.168-282.144-329.17c8.14-3.696 17.006-5.66 26.144-5.66zM192 704v-384c0-1.34 0.056-2.672 0.14-4l187.664 218.942-185.598 185.598c-1.444-5.336-2.206-10.886-2.206-16.54zM768 768h-512c-5.654 0-11.202-0.762-16.54-2.208l182.118-182.118 90.422 105.498 90.424-105.494 182.116 182.12c-5.34 1.44-10.886 2.202-16.54 2.202zM832 704c0 5.654-0.762 11.2-2.206 16.54l-185.6-185.598 187.666-218.942c0.084 1.328 0.14 2.66 0.14 4v384z"></path>
          </svg>
          <span class="navbar-text2">
            <span>Reach out to us</span>
          </span>
        </a>
      </div>
      <div data-thq="thq-burger-menu" class="navbar-burger-menu">
        <svg viewBox="0 0 1024 1024" class="navbar-icon2">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div data-thq="thq-mobile-menu" class="navbar-mobile-menu">
        <div data-thq="thq-mobile-menu-nav" data-role="Nav" class="navbar-nav">
          <div class="navbar-container1">
            <img
              alt="image"
              src="public/Branding/untitled%20design%20(6)-1500h.png"
              class="navbar-image1"
            />
            <div data-thq="thq-close-menu" class="navbar-menu-close">
              <svg viewBox="0 0 1024 1024" class="navbar-icon4">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
          <nav
            data-thq="thq-mobile-menu-nav-links"
            data-role="Nav"
            class="navbar-nav1"
          >
            <a href="projects.html" class="navbar-text3">
              <span>Projects</span>
            </a>
            <a href="services.html" class="navbar-navlink1">
              <span>Services</span>
            </a>
            <a href="clients.html" class="navbar-navlink2">
              <span>Clients</span>
            </a>
            <button class="navbar-phone1 button">
              <img
                alt="image"
                src="public/Icons/phone.svg"
                class="navbar-image2"
              />
              <a href="tel:+968-71160030" class="navbar-link5">
                <span>+968-71160030</span>
              </a>
            </button>
            <a href="#book" class="navbar-book1 button button-main">
              <span class="navbar-text5">
                <span>Reach out to us</span>
              </span>
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
